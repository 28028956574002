@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: HitandRun;
  src: url("./assets/fonts/Title/HitandRun-Regular.otf");
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("./assets/fonts/Description/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: Montserrat-ExtraBoldItalic;
  src: url("./assets/fonts/Description/Montserrat-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: Montserrat-Black;
  src: url("./assets/fonts/Description/Montserrat-Black.ttf");
}
@font-face {
  font-family: Montserrat-BlackItalic;
  src: url("./assets/fonts/Description/Montserrat-BlackItalic.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("./assets/fonts/Description/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-BoldItalic;
  src: url("./assets/fonts/Description/Montserrat-BoldItalic.ttf");
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("./assets/fonts/Description/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat-SemiBoldItalic;
  src: url("./assets/fonts/Description/Montserrat-SemiBoldItalic.ttf");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url("./assets/fonts/Description/Montserrat-Medium.ttf");
}
@font-face {
  font-family: Montserrat-MediumItalic;
  src: url("./assets/fonts/Description/Montserrat-MediumItalic.ttf");
}
@font-face {
  font-family: Montserrat-Regular;
  src: url("./assets/fonts/Description/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Italic;
  src: url("./assets/fonts/Description/Montserrat-Italic.ttf");
}
@font-face {
  font-family: Montserrat-Thin;
  src: url("./assets/fonts/Description/Montserrat-Thin.ttf");
}
@font-face {
  font-family: Montserrat-ThinItalic;
  src: url("./assets/fonts/Description/Montserrat-ThinItalic.ttf");
}
@font-face {
  font-family: Montserrat-Light;
  src: url("./assets/fonts/Description/Montserrat-Light.ttf");
}
@font-face {
  font-family: Montserrat-LightItalic;
  src: url("./assets/fonts/Description/Montserrat-LightItalic.ttf");
}
body {
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  font-family: Montserrat-Regular;
  scroll-behavior: smooth;
  text-decoration: none;
}

#root {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

